import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';

import { TermsAndConditionsWrapper } from './gdprContent.style';

const TermsAndConditions = ({
  sectionWrapper,
  title,
  textArea
}) => {
  
  return (
    <Box {...sectionWrapper} as="section">
      <Container noGutter mobileGutter width="1200px">
        <TermsAndConditionsWrapper>
          <Box {...textArea}>
            <Heading content="Borrado de datos" {...title} />
            <div class="description">

              <p>De conformidad con lo dispuesto en las normativas vigentes en protección de datos personales, el Reglamento (UE) 2016/679 de 27 de abril de 2016 (GDPR), se informa que los datos de carácter personal proporcionados mediante la aceptación de los <a href="/legal/condiciones-de-uso">términos y condiciones</a>, formarán parte de un fichero responsabilidad de Loobi Hub SL y que estos serán tratados con la finalidad descrita en la <a href="/legal/condiciones-de-uso">política de privacidad</a> y serán conservados mientras dure la relación contractual objeto del uso de la APLICACIÓN, con el único objetivo de facilitar la introducción de mejoras en futuras versiones de la APLICACIÓN, también podrá realizarse el tratamiento de la información de las instalaciones, accesos de usuarios, datos demográficos, pantallas e interacción del usuario y bloqueos y excepciones.</p>

              <p>Asimismo, se informa que podrá retirar el consentimiento.</p>

              <p>En el caso de que se desee el borrado de los datos almacenados por Loobi Hub SL sera necesario escrbir un email a la direccion <a href='mailto:gdpr@loobi.com'>gdrp</a>.</p>

              </div>
          </Box>
        </TermsAndConditionsWrapper>
      </Container>
    </Box>
  );
};


TermsAndConditions.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  buttonArea: PropTypes.object,
  buttonStyle: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

TermsAndConditions.defaultProps = {
  sectionWrapper: {},
  textArea: {
    width: ['100%', '100%', '100%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  title: {
    fontSize: ['20px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '15px', '18px', '22px', '22px'],
    textAlign: ['center', 'center', 'left', 'left', 'left'],
  },
  description: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    textAlign: ['center', 'center', 'left', 'left', 'left'],
  }
};

export default TermsAndConditions;
